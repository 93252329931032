/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

import { defineMessages } from 'react-intl';
//
// Default messages are 'en-US'
//
const messages = defineMessages({
  discuss: {
    id: 'discuss',
    description: 'Discuss',
    defaultMessage: 'Discuss'
  },
  summarize: {
    id: 'summarize',
    description: 'Summarize',
    defaultMessage: 'Summarize'
  },
  practice: {
    id: 'practice',
    description: 'practice',
    defaultMessage: 'Practice'
  },
  tutor: {
    id: 'tutor',
    description: 'AI Study Tool',
    defaultMessage: 'AI Study Tool'
  },
  studyTool: {
    id: 'studyTool',
    description: 'Study Tool',
    defaultMessage: 'Study Tool'
  },
  beta: {
    id: 'beta',
    description: 'Beta',
    defaultMessage: 'Beta'
  },
  send: {
    id: 'send',
    description: 'Send',
    defaultMessage: 'Send'
  },
  clearChat: {
    id: 'clearChat',
    description: 'New topic',
    defaultMessage: 'New topic'
  },
  inputPlaceHolder: {
    id: 'inputPlaceHolder',
    description: 'Type a message',
    defaultMessage: 'Type a message'
  },
  footerMessage: {
    id: 'footerMessage',
    description: 'Please don’t share personal data, and keep in mind that AI can be inaccurate. For more info, see our',
    defaultMessage: 'Please don’t share personal data, and keep in mind that AI can be inaccurate. For more info, see our'
  },
  footerSubMessage: {
    id: 'footerSubMessage',
    description: 'AI responses can be inaccurate.',
    defaultMessage: 'AI responses can be inaccurate.'
  },
  footerLinkText: {
    id: 'footerLinkText',
    description: 'FAQs',
    defaultMessage: 'FAQs'
  },
  close: {
    id: 'close',
    description: 'Close',
    defaultMessage: 'Close'
  },
  expanded: {
    id: 'expanded',
    description: 'Expanded',
    defaultMessage: 'Expanded'
  },
  default: {
    id: 'default',
    description: 'Default',
    defaultMessage: 'Default'
  },
  seeMore: {
    id: 'seeMore',
    description: 'See more',
    defaultMessage: 'See more'
  },
  seeLess: {
    id: 'seeLess',
    description: 'See less',
    defaultMessage: 'See less'
  },
  clarifyTitle: {
    id: 'clarifyTitle',
    description: 'Sure. What should I summarize?',
    defaultMessage: 'Sure. What should I summarize?'
  },
  clarifyQuizTitle: {
    id: 'clarifyQuizTitle',
    description: 'Sure. Where should I pull those questions from?',
    defaultMessage: 'Sure. Where should I pull those questions from?'
  },
  quizHelpText: {
    id: 'quizHelpText',
    description: 'You can also choose a general topic by typing it out below.​',
    defaultMessage: 'You can also choose a general topic by typing it out below.​'
  },
  currentPage: {
    id: 'currentPage',
    description: 'Current page',
    defaultMessage: 'Current page'
  },
  currentSection: {
    id: 'currentSection',
    description: 'Current section',
    defaultMessage: 'Current section'
  },
  currentChapter: {
    id: 'currentChapter',
    description: 'Whole chapter',
    defaultMessage: 'Whole chapter'
  },
  pageRange: {
    id: 'pageRange',
    description: 'Page range',
    defaultMessage: 'Page range'
  },
  chapters: {
    id: 'chapters',
    description: 'Chapter(s)',
    defaultMessage: 'Chapter(s)'
  },
  skip: {
    id: 'skip',
    description: 'Skip',
    defaultMessage: 'Skip'
  },
  submit: {
    id: 'submit',
    description: 'Submit',
    defaultMessage: 'Submit'
  },
  cancel: {
    id: 'cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  next: {
    id: 'next',
    description: 'Next',
    defaultMessage: 'Next'
  },
  no: {
    id: 'no',
    description: 'I\'m done.',
    defaultMessage: 'I\'m done.'
  },
  yes: {
    id: 'yes',
    description: 'Yes',
    defaultMessage: 'Yes'
  },
  moreQuestionTitle: {
    id: 'moreQuestionTitle',
    description: 'Do you want to see more questions like this?',
    defaultMessage: 'Do you want to see more questions like this?'
  },
  pageRangeError: {
    id: 'pageRangeError',
    description: 'Please provide a page range.',
    defaultMessage: 'Please provide a page range.'
  },
  pageRangeError1: {
    id: 'pageRangeError1',
    description: 'Please provide a page range with -.',
    defaultMessage: 'Please provide a page range with -.'
  },
  correctAnswer: {
    id: 'correctAnswer',
    decritpion: 'Correct Answer',
    defaultMessage: 'Correct Answer'
  },
  wrongAnswer: {
    id: 'wrongAnswer',
    description: 'Wrong Answer',
    defaultMessage: 'Wrong Answer'
  },
  confirm: {
    id: 'confirm',
    description: 'Confirm',
    defaultMessage: 'Confirm'
  },
  to: {
    id: 'to',
    description: 'to',
    defaultMessage: 'to'
  },
  saveNote: {
    id: 'saveNotes',
    description: 'Save as a note',
    defaultMessage: 'Save as a note'
  },
  saveFlashcard: {
    id: 'saveFlashcard',
    description: 'Save as flashcard',
    defaultMessage: 'Save as flashcard'
  },
  saveFlashcards: {
    id: 'saveFlashcards',
    description: 'Save as flashcards',
    defaultMessage: 'Save as flashcards'
  },
  regenerate: {
    id: 'regenerate',
    description: 'Regenerate',
    defaultMessage: 'Regenerate'
  },
  copy: {
    id: 'copy',
    description: 'Copy',
    defaultMessage: 'Copy'
  },
  positiveFeedbackHeading: {
    id: 'positiveFeedbackHeading',
    description: 'Great!',
    defaultMessage: 'Great!'
  },
  feedbacksubmitMessage: {
    id: 'feedbacksubmitMessage',
    description: 'Thanks for the feedback!',
    defaultMessage: 'Thanks for the feedback!'
  },
  dismiss: {
    id: 'dismiss',
    description: 'Dismiss',
    defaultMessage: 'Dismiss'
  },
  positiveFeedbackDescription: {
    id: 'positiveFeedbackDescription',
    description: 'What did you like most about this response?',
    defaultMessage: 'What did you like most about this response?'
  },
  positiveFeedbackPlaceHolder: {
    id: 'positiveFeedbackPlaceHolder',
    description: 'Type your reason here...',
    defaultMessage: 'Type your reason here...'
  },
  negativeFeedbackHeading: {
    id: 'negativeFeedbackHeading',
    description: 'Sorry about that!',
    defaultMessage: 'Sorry about that!'
  },
  negativeFeedbackDescription: {
    id: 'negativeFeedbackDescription',
    description: 'What was wrong with that response?',
    defaultMessage: 'What was wrong with that response?'
  },
  negativeFeedbackPlaceHolder: {
    id: 'negativeFeedbackPlaceHolder',
    description: 'Please explain...',
    defaultMessage: 'Please explain...'
  },
  feedbackSubHeading: {
    id: 'feedbackSubHeading',
    description: 'Your feedback helps our AI improve',
    defaultMessage: 'Your feedback helps our AI improve'
  },
  summaryDescription: {
    id: 'summaryDescription',
    description: 'Get a summary of selected pages or entire chapters.',
    defaultMessage: 'Get a summary of selected pages or entire chapters.'
  },
  summaryNeWTopicDescription: {
    id: 'summaryNeWTopicDescription',
    description: 'Getting a summary of selected pages or entire chapters.',
    defaultMessage: 'Getting a summary of selected pages or entire chapters.'
  },
  discussNewTopicDescription: {
    id: 'discussNewTopicDescription',
    description: 'Having a topic or concept explained.',
    defaultMessage: 'Having a topic or concept explained.'
  },
  quizNeWTopicDescription: {
    id: 'quizNeWTopicDescription',
    description: 'Checking your understanding of key concepts with practice questions.',
    defaultMessage: 'Checking your understanding of key concepts with practice questions.'
  },
  summaryDescriptionForContentSelector: {
    id: 'summaryDescriptionForContentSelector',
    description: 'Get a summary created from the content',
    defaultMessage: 'Get a summary created from the content'
  },
  quizDescription: {
    id: 'quizDescription',
    description: 'Check your understanding of key concepts with practice questions.',
    defaultMessage: 'Check your understanding of key concepts with practice questions.'
  },
  quizDescriptionForContentSelector: {
    id: 'quizDescriptionForContentSelector',
    description: 'Check your understanding of the content with practice questions',
    defaultMessage: 'Check your understanding of the content with practice questions'
  },
  discussDescription: {
    id: 'discussDescription',
    description: 'Have a topic or concept explained.',
    defaultMessage: 'Have a topic or concept explained.'
  },
  welcomeMessage: {
    id: 'welcomeMessage',
    description: 'Welcome! I\'m an AI here to help you get the most out of',
    defaultMessage: 'Welcome! I\'m an AI here to help you get the most out of.'
  },
  welcomeBackMessage: {
    id: 'welcomeBackMessage',
    description: 'Welcome back!',
    defaultMessage: 'Welcome back!'
  },
  welcomeMessage1: {
    id: 'welcomeMessage1',
    description: 'When you\'re ready, make a request in the textbox below. You can also get started by choosing from these common requests:',
    defaultMessage: 'When you\'re ready, make a request in the textbox below. You can also get started by choosing from these common requests:'
  },
  welcomeFooterMessage1: {
    id: 'welcomeFooterMessage1',
    description: 'Feel free to ask me anything related to the eTextbook, and I\'ll be delighted to assist you!',
    defaultMessage: 'Feel free to ask me anything related to the eTextbook, and I\'ll be delighted to assist you!'
  },
  welcomeBackFooterMessage1: {
    id: 'welcomeFooterMessage1',
    description: 'As always, don\'t hesitate to ask me anything related to the eTextbook. I am here to help you get the most from it!',
    defaultMessage: 'As always, don\'t hesitate to ask me anything related to the eTextbook. I am here to help you get the most from it!'
  },
  welcomeCSMessage1: {
    id: 'welcomeCSMessage1',
    description: 'I see that you already selected content you\'d like me to use. Go ahead and type in a message down below to tell me what to do with it, or, even easier, try one of these:',
    defaultMessage: 'I see that you already selected content you\'d like me to use. Go ahead and type in a message down below to tell me what to do with it, or, even easier, try one of these:'
  },
  welcomeCSMessageSecondTime: {
    id: 'welcomeCSMessageSecondTime',
    description: 'I see that you selected new content for me to use. You can simply type a message below to let me know what to do with it, or, even easier, try one of these:',
    defaultMessage: 'I see that you selected new content for me to use. You can simply type a message below to let me know what to do with it, or, even easier, try one of these:'
  },
  newTopicWelcomeMessage: {
    id: 'newTopicWelcomeMessage',
    description: 'New topic it is!',
    defaultMessage: 'New topic it is!'
  },
  newTopicWelcomeMessage1: {
    id: 'newTopicWelcomeMessage1',
    description: 'What can I help you with next?',
    defaultMessage: 'What can I help you with next?'
  },
  summaryOptionTitle: {
    id: 'summaryOptionTitle',
    description: 'Sure thing. How should I format it?',
    defaultMessage: 'Sure thing. How should I format it?'
  },
  bullet: {
    id: 'bullet',
    description: 'Use bullet points',
    defaultMessage: 'Use bullet points'
  },
  simpleExplanation: {
    id: 'simpleExplanation',
    description: 'Give me a simple explanation',
    defaultMessage: 'Give me a simple explanation'
  },
  askQuestion: {
    id: 'askQuestion',
    description: 'Ask me a question about it and give me feedback on my response',
    defaultMessage: 'Ask me a question about it and give me feedback on my response'
  },
  paragraph: {
    id: 'paragraph',
    description: 'Use paragraphs',
    defaultMessage: 'Use paragraphs'
  },
  discussFollowUpText: {
    id: 'discussFollowUpText',
    description: 'What topic or term from your eTextbook would you like explained?',
    defaultMessage: 'What topic or term from your eTextbook would you like explained?'
  },
  topicMessageForFirstTimeUser: {
    id: 'topicMessageForFirstTimeUser',
    description: 'I\'m seeing multiple topics in the content you’ve selected. Which would you like me to explain?',
    defaultMessage: 'I\'m seeing multiple topics in the content you’ve selected. Which would you like me to explain?'
  },
  topicMessageForSecondTimeUser: {
    id: 'topicMessageForSecondTimeUser',
    description: 'I\'m seeing multiple topics in the content you’ve selected. Which would you like me to explain?',
    defaultMessage: 'I\'m seeing multiple topics in the content you’ve selected. Which would you like me to explain?'
  },
  singleTopicMessageForFirstTimeUser: {
    id: 'singleTopicMessageForFirstTimeUser',
    description: 'In the content you\'ve selected, I\'m finding one key concept:',
    defaultMessage: 'In the content you\'ve selected, I\'m finding one key concept:'
  },
  singleTopicMessageForSecondTimeUser: {
    id: 'singleTopicMessageForSecondTimeUser',
    description: 'In the content you\'ve selected, I\'m finding one key concept:',
    defaultMessage: 'In the content you\'ve selected, I\'m finding one key concept:'
  },
  moreOnSingleTopic: {
    id: 'moreOnSingleTopic',
    description: 'Here\'s more on that topic:',
    defaultMessage: 'Here\'s more on that topic:'
  },
  insufficientContent: {
    id: 'insufficientContent',
    description: 'Please select more content from the book if needed, or type in down below how I can help with something else.',
    defaultMessage: 'Please select more content from the book if needed, or type in down below how I can help with something else.'
  },
  insufficientContentFeed: {
    id: 'insufficientContentFeed',
    description: 'I see you\'ve selected some content, but I can\'t pinpoint a topic to explain or summarize. Name a topic you\'d like to learn more about or select a larger block of content.',
    defaultMessage: 'I see you\'ve selected some content, but I can\'t pinpoint a topic to explain or summarize. Name a topic you\'d like to learn more about or select a larger block of content.'
  },
  alternatively: {
    id: 'alternatively',
    description: 'You can also choose your own topic by asking for it below.',
    defaultMessage: 'You can also choose your own topic by asking for it below.'
  },
  chooseTopics: {
    id: 'chooseTopics',
    description: 'You can also choose your own topic by typing it below.',
    defaultMessage: 'You can also choose your own topic by typing it below.'
  },
  quizOptionTitle: {
    id: 'quizOptionTitle',
    description: 'What kind of questions are you looking for?',
    defaultMessage: 'What kind of questions are you looking for?'
  },
  multipleChoice: {
    id: 'multipleChoice',
    description: 'Multiple choice',
    defaultMessage: 'Multiple choice'
  },
  freeResponse: {
    id: 'freeResponse',
    description: 'Short answer',
    defaultMessage: 'Short answer'
  },
  findChapters: {
    id: 'findChapters',
    description: 'Find chapters',
    defaultMessage: 'Find chapters'
  },
  discussOptionText: {
    id: 'discussOptionText',
    description: 'Would you prefer that I provide a simple explanation or that we have a conversation where I ask you questions and give feedback on your answers to help better understand the topic?',
    defaultMessage: 'Would you prefer that I provide a simple explanation or that we have a conversation where I ask you questions and give feedback on your answers to help better understand the topic?'
  },
  successFeedbackMessage: {
    id: 'successFeedbackMessage',
    description: 'Feedback submitted successfully',
    defaultMessage: 'Feedback submitted successfully'
  },
  contactSupportHeader: {
    id: 'contactSupportHeader',
    description: 'It looks like you could use help from our customer support team.',
    defaultMessage: 'It looks like you could use help from our customer support team.'
  },
  contactSupportDescription: {
    id: 'contactSupportDescription',
    description: 'Find tips for common issues or talk directly with an agent here. ',
    defaultMessage: 'Find tips for common issues or talk directly with an agent here. '
  },
  clickHere: {
    id: 'clickHere',
    description: 'Click here.',
    defaultMessage: 'Click here.'
  },
  noteCardCreated: {
    id: 'noteCardCreated',
    description: 'Note successfully created',
    defaultMessage: 'Note successfully created'
  },
  flashcardCreated: {
    id: 'flashcardCreated',
    description: 'Flashcards successfully created',
    defaultMessage: 'Flashcards successfully created'
  },
  textCopiedMessage: {
    id: 'textCopiedMessage',
    description: 'Text copied successfully',
    defaultMessage: 'Text copied successfully'
  },
  somethingWentWrong: {
    id: 'somethingWentWrong',
    description: 'Oops! Something went wrong',
    defaultMessage: 'Oops! Something went wrong'
  },
  autoOpenWelcomeMessage: {
    id: 'autoOpenWelcomeMessage',
    description: 'autoOpenWelcomeMessage',
    defaultMessage: 'You can also try one of these common requests:'
  },
  autoOpenWelcomeHeaderMessage: {
    id: 'autoOpenWelcomeHeaderMessage',
    defaultMessage: 'Hi there! What can I help you with today?',
    description: 'Hi there! What can I help you with today?'
  },
  welcomeMessageSub: {
    id: 'welcomeMessageSub',
    description: 'My job as an AI learning assistant is to help you get the most out of your eTextbook.',
    defaultMessage: 'My job as an AI learning assistant is to help you get the most out of your eTextbook.'
  },
  readyForTheNextQuestion: {
    id: 'readyForTheNextQuestion',
    description: 'Ready for the next question?',
    defaultMessage: 'Ready for the next question?'
  },
  aiLearningAssistant: {
    id: 'aiLearningAssistant',
    description: 'AI Study Tool',
    defaultMessage: 'AI Study Tool'
  },
  chatHistoryToolTip: {
    id: 'chatHistoryToolTip',
    description: 'Chat History',
    defaultMessage: 'Chat History'
  },
  problemSolvingFeatureIntentTypeMessage: {
    id: 'problemSolvingFeatureIntentTypeMessage',
    description: 'As your eTextbook AI Study Tool, I\'m here to help you make the most of your book. I can provide you with page and chapter summaries, practice questions, and explanations for important concepts. However, I can\'t give you direct answers to your homework or assessment problems. But don\'t worry, if you need any assistance with the options I mentioned earlier, just let me know, and I\'ll be happy to lend a hand!',
    defaultMessage: 'As your eTextbook AI Study Tool, I\'m here to help you make the most of your book. I can provide you with page and chapter summaries, practice questions, and explanations for important concepts. However, I can\'t give you direct answers to your homework or assessment problems. But don\'t worry, if you need any assistance with the options I mentioned earlier, just let me know, and I\'ll be happy to lend a hand!'
  },
  unknownIntentTypeInitialMessage: {
    id: 'unknownIntentTypeInitialMessage',
    description: 'Apologies, I am not sure yet how to help. Try rephrasing your message or select from one of these options:',
    defaultMessage: 'Apologies, I am not sure yet how to help. Try rephrasing your message or select from one of these options:'
  },
  summaryOfCurrentPage: {
    id: 'summaryOfCurrentPage',
    description: 'Can you summarize something for me?',
    defaultMessage: 'Can you summarize something for me?'
  },
  practiceQuestions: {
    id: 'practiceQuestions',
    description: 'Can you generate some practice questions?',
    defaultMessage: 'Can you generate some practice questions?'
  },
  freeResponseCopyText: {
    id: 'freeResponseCopyText',
    description: 'This is just practice, so skip a question or end the quiz whenever you like.',
    defaultMessage: 'This is just practice, so skip a question or end the quiz whenever you like.'
  },
  stopQuizWelcomeMessage: {
    id: 'stopQuizWelcomeMessage',
    description: 'What can I help with next?',
    defaultMessage: 'What can I help with next?'
  },
  subWelcomeMessage1: {
    id: 'subWelcomeMessage1',
    description: 'I\'m here to help with anything related to ',
    defaultMessage: 'I\'m here to help with anything related to '
  },
  subWelcomeMessage2: {
    id: 'subWelcomeMessage2',
    description: '. Make a request below or choose from these options',
    defaultMessage: '. Make a request below or choose from these options'
  },
  welcomeBtnSummarize: {
    id: 'welcomeBtnSummarize',
    description: 'Summarize',
    defaultMessage: 'Summarize'
  },
  welcomeBtnPractice: {
    id: 'welcomeBtnPractice',
    description: 'Practice',
    defaultMessage: 'Practice'
  },
  welcomeBtnExplain: {
    id: 'welcomeBtnExplain',
    description: 'Explain',
    defaultMessage: 'Explain'
  },
  suggestionPillsText: {
    id: 'suggestionPillsText',
    description: 'Can I help you with anything else from the',
    defaultMessage: 'Can I help you with anything else from the'
  },
  delayedResponseMessage: {
    id: 'delayedResponseMessage',
    description: 'This is taking longer than expected. This happens from time to time, since I\'m built with new technology. Would you like to wait?',
    defaultMessage: 'This is taking longer than expected. This happens from time to time, since I\'m built with new technology. Would you like to wait?'
  },
  chapterTopicTitle: {
    id: 'chapterTopicTitle',
    description: 'What content should I use?',
    defaultMessage: 'What content should I use?'
  },
  onBoardingWelcomeMessage: {
    id: 'onBoardingWelcomeMessage',
    description: 'Welcome!',
    defaultMessage: 'Welcome!'
  },
  onBoardingDescription: {
    id: 'onBoardingDescription',
    description: 'I’m here to help. Ask me questions and I’ll provide:',
    defaultMessage: 'I’m here to help. Ask me questions and I’ll provide:'
  },
  onBoardingPracticeMessage: {
    id: 'onBoardingPracticeMessage',
    description: 'Practice questions to get test-ready',
    defaultMessage: 'Practice questions to get test-ready'
  },
  onBoardingExplainMessage: {
    id: 'onBoardingExplainMessage',
    description: 'Simple explanations to learn tricky concepts',
    defaultMessage: 'Simple explanations to learn tricky concepts'
  },
  onBoardingSummaryMessage: {
    id: 'onBoardingSummaryMessage',
    description: 'Chapter summaries to study faster and easier',
    defaultMessage: 'Chapter summaries to study faster and easier'
  },
  onBoardingAutoOpenDescription: {
    id: 'onBoardingAutoOpenDescription'
  },
  onPracticeMessage: {
    id: 'onPracticeMessage'
  },
  onExplainMessage: {
    id: 'onExplainMessage'
  },
  onSummaryMessage: {
    id: 'onSummaryMessage'
  },
  retrievingData: {
    id: 'retrievingData',
    description: 'retrieving data',
    defaultMessage: 'retrieving data'
  },
  quizLoaderMessage: {
    id: 'quizLoaderMessage',
    description: 'I\'m on it! Give me a moment while I work on that.',
    defaultMessage: 'I\'m on it! Give me a moment while I work on that.'
  },
  noQuizQuestion: {
    id: 'noQuizQuestion',
    description: 'Nothing on this page will make good practice questions. Go to another page or ask me for something specific.',
    defaultMessage: 'Nothing on this page will make good practice questions. Go to another page or ask me for something specific.'
  },
  tellMore: {
    id: 'tellMore',
    description: 'Tell me more',
    defaultMessage: 'Tell me more'
  },
  moveOn: {
    id: 'moveOn',
    description: 'Let\'s move on',
    defaultMessage: 'Let\'s move on'
  },
  moveOnDescription: {
    id: 'moveOnDescription',
    description: 'Sure thing. What\'s next?',
    defaultMessage: 'Sure thing. What\'s next?'
  },
  stopDescription: {
    id: 'stopDescription',
    description: 'Okay, let\'s move on. Is there something else you\'d like to try?',
    defaultMessage: 'Okay, let\'s move on. Is there something else you\'d like to try?'
  },
  summaryQuizDescription: {
    id: 'summaryQuizDescription',
    description: 'Would you like to follow up that summary with some practice questions on the material?',
    defaultMessage: 'Would you like to follow up that summary with some practice questions on the material?'
  },
  summaryQuizMe: {
    id: 'summaryQuizMe',
    description: 'Yes, quiz me!',
    defaultMessage: 'Yes, quiz me!'
  },
  summaryMoveOn: {
    id: 'summaryMoveOn',
    description: 'No, let\'s move on',
    defaultMessage: 'No, let\'s move on'
  },
  explainQuizDescription: {
    id: 'explainQuizDescription',
    description: 'Want to test your understanding with a follow-up question on that topic?',
    defaultMessage: 'Want to test your understanding with a follow-up question on that topic?'
  },
  explainQuizMe: {
    id: 'explainQuizMe',
    description: 'Yes, give me a question',
    defaultMessage: 'Yes, give me a question'
  },
  comprehensiveCheck: {
    id: 'comprehensiveCheck',
    description: 'Comprehension check!',
    defaultMessage: 'Comprehension check!'
  },
  problemSolveLoaderMessage: {
    id: 'problemSolveLoaderMessage',
    description: 'Actually, something about this doesn\'t seem right. Hold on while I check my work.',
    defaultMessage: 'Actually, something about this doesn\'t seem right. Hold on while I check my work.'
  },
  specificTopic: {
    id: 'specificTopic',
    description: 'A specific topic',
    defaultMessage: 'A specific topic'
  },
  topicFollowUpText: {
    id: 'topicFollowUpText',
    description: 'Okay, what topic? It could be anything related to this eTextbook.',
    defaultMessage: 'Okay, what topic? It could be anything related to this eTextbook.'
  },
  specificTopicUserMessage: {
    id: 'specificTopicUserMessage',
    description: 'Use content about a specific topic',
    defaultMessage: 'Use content about a specific topic'
  },
  chatHistory: {
    id: 'chatHistory',
    description: 'Chat history',
    defaultMessage: 'Chat history'
  },
  historyListTitle: {
    id: 'historyListTitle',
    description: 'Latest activity',
    defaultMessage: 'Latest activity'
  },
  historySearchPlaceHolder: {
    id: 'historySearchPlaceHolder',
    description: 'Search...',
    defaultMessage: 'Search...'
  },
  noSearchResult: {
    id: 'noSearchResult',
    description: 'No matches for ',
    defaultMessage: 'No matches for '
  },
  rename: {
    id: 'rename',
    description: 'Rename',
    defaultMessage: 'Rename'
  },
  delete: {
    id: 'delete',
    description: 'Delete',
    defaultMessage: 'Delete'
  },
  noHistoryDataTitle: {
    id: 'noHistoryDataTitle',
    description: 'Your chat history is empty',
    defaultMessage: 'Your chat history is empty'
  },
  noHistoryDataDescription: {
    id: 'noHistoryDataDescription',
    description: 'Every time you start a new topic, your conversation will be saved here.',
    defaultMessage: 'Every time you start a new topic, your conversation will be saved here.'
  },
  chatDeleted: {
    id: 'chatDeleted',
    description: 'Chat deleted.',
    defaultMessage: 'Chat deleted.'
  },
  chatRenamed: {
    id: 'chatRenamed',
    description: 'Chat renamed.',
    defaultMessage: 'Chat renamed.'
  },
  undo: {
    id: 'undo',
    description: 'Undo',
    defaultMessage: 'Undo'
  },
  studyAndExamPrepHeading: {
    id: 'studyAndExamPrepHeading',
    description: 'Here\'s a video from premium Study & Exam Prep content on this topic:',
    defaultMessage: 'Here\'s a video from premium Study & Exam Prep content on this topic:'
  },
  unlockUnlimitedAccess: {
    id: 'unlockUnlimitedAccess',
    description: 'Unlock unlimited access to Study & Exam Prep videos and more',
    defaultMessage: 'Unlock unlimited access to Study & Exam Prep videos and more'
  },
  outOfFreeVideos: {
    id: 'outOfFreeVideos',
    description: 'You’re out of free videos, but Study & Exam Prep has so much more to offer.',
    defaultMessage: 'You’re out of free videos, but Study & Exam Prep has so much more to offer.'
  },
  subscribeNowFor: {
    id: 'subscribeNowFor',
    description: 'Subscribe now for:',
    defaultMessage: 'Subscribe now for:'
  },
  unlimitedVideoLessons: {
    id: 'unlimitedVideoLessons',
    description: 'Unlimited video lessons',
    defaultMessage: 'Unlimited video lessons'
  },
  examLikePracticeQuestions: {
    id: 'examLikePracticeQuestions',
    description: 'Exam-like practice questions',
    defaultMessage: 'Exam-like practice questions'
  },
  personalizedStudyPlans: {
    id: 'personalizedStudyPlans',
    description: 'Personalized study plans',
    defaultMessage: 'Personalized study plans'
  },
  subscribe: {
    id: 'subscribe',
    description: 'Subscribe',
    defaultMessage: 'Subscribe'
  },
  video: {
    id: 'video',
    description: 'VIDEO',
    defaultMessage: 'VIDEO'
  },
  views: {
    id: 'views',
    description: 'views',
    defaultMessage: 'views'
  },
  page: {
    id: 'page',
    description: '- pg.',
    defaultMessage: '- pg.'
  },
  dontShowChannelVideo: {
    id: 'dontShowChannelVideo',
    description: 'Don’t show Study & Exam Prep videos',
    defaultMessage: 'Don’t show Study & Exam Prep videos'
  },
  hideVideoUserMessage: {
    id: 'hideVideoUserMessage',
    description: 'No more Study & Exam Prep videos',
    defaultMessage: 'No more Study & Exam Prep videos'
  },
  hideVideoCannedMessage: {
    id: 'hideVideoCannedMessage',
    description: 'Got it! Study & Exam Prep videos are disabled. What else can I help with?',
    defaultMessage: 'Got it! Study & Exam Prep videos are disabled. What else can I help with?'
  },
  summaryNotSupported: {
    id: 'summaryNotSupported',
    description: 'Sorry, Currently we are not supporting summarize feature for this book.',
    defaultMessage: 'Sorry, Currently we are not supporting summarize feature for this book.'
  },
  practiceNotSupported: {
    id: 'practiceNotSupported',
    description: 'Sorry, Currently we are not supporting practice feature for this book.',
    defaultMessage: 'Sorry, Currently we are not supporting practice feature for this book.'
  },
  recommendationPromptTitle: {
    id: 'recommendationPromptTitle',
    description: 'Do you want me to explain a key topic from this page?',
    defaultMessage: 'Do you want me to explain a key topic from this page?'
  },
  recommendationCtaTitle: {
    id: 'recommendationCtaTitle',
    description: 'Try one of these common requests:',
    defaultMessage: 'Try one of these common requests:'
  },
  recommendationPromptCtaTitle: {
    id: 'recommendationPromptCtaTitle',
    description: 'Or try one of these common requests:',
    defaultMessage: 'Or try one of these common requests:'
  },
  studyAndExamPrepHeadingOptIn: {
    id: 'studyAndExamPrepHeadingOptIn',
    description: 'No problem! Here’s a video from premium Study & Exam Prep content on this topic:',
    defaultMessage: 'No problem! Here’s a video from premium Study & Exam Prep content on this topic:'
  },
  showMeStudyAndExamPrepVideos: {
    id: 'showMeStudyAndExamPrepVideos',
    description: 'Show me Study & Exam Prep videos',
    defaultMessage: 'Show me Study & Exam Prep videos'
  },
  showStudyAndExamPrepVideos: {
    id: 'showStudyAndExamPrepVideos',
    description: 'Show Study & Exam Prep videos',
    defaultMessage: 'Show Study & Exam Prep videos'
  },
  imageSomethingElseCannedMessage: {
    id: 'imageSomethingElseCannedMessage',
    description: 'Let me know what you need, and I\'ll do my best to help!',
    defaultMessage: 'Let me know what you need, and I\'ll do my best to help!'
  },
  viewInContent: {
    id: 'viewInContent',
    description: 'View in content',
    defaultMessage: 'View in content'
  },
  imageCopilotHelperText: {
    id: 'imageCopilotHelperText',
    description: 'Hey there! How can I help with this?',
    defaultMessage: 'Hey there! How can I help with this?'
  },
  threadChangeMessage: {
    id: 'threadChangeMessage',
    description: 'Looks like you want to move on to a new topic.',
    defineMessages: 'Looks like you want to move on to a new topic.'
  },
  moreActions: {
    id: 'moreActions',
    description: 'More actions',
    defineMessages: 'More actions'

  }
});
export default messages;
